/* eslint-disable import/no-anonymous-default-export */
export default {
  siteKey: "4bee9ad3-4372-4d7a-8230-bef0f7ca3f21",
  dummyLogo:
    "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png",

  googleSecretkey: "6LdZLtQhAAAAAAK-P4TFCfnNJpbeYo_y8zDlOvWN",
  googleSitekey: "6LdZLtQhAAAAALBXe6Qhvm7C_gKdo-PqA4fxuxmi",
  GOOGLE_RECAPTCHA_SITE_KEY: "6LcQdsYqAAAAALqoC8es5XgIp3hSIfKZ9Ps76Pje"

  //6Ldt7hAiAAAAAEcNAVSCkus1fdwSNMBWAMjbTk_f
};
